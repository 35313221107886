import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(async () => {
  // skip middleware on server
  if (process.server) return

  const { $sdk, $normalizePath } = useNuxtApp()
  const account = await $sdk
    .module('auth')
    .then(({ getAccount }) => getAccount())

  if (!account) {
    const redirectPath = $normalizePath('/')
    return navigateTo(redirectPath)
  }

  // // skip middleware on client side entirely
  // if (process.client) return
  // // or only skip middleware on initial client load
  // if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return
})
